<style scoped lang="less">
  .evaluation-details-draw {
    display: flex;
    flex-direction: column;
  }
  .content {
    padding: 10px;
    border-top: 1px solid #EEE;
    flex: 1;
    display: flex;
    height: 0;
    .left {
      display: flex;
      flex-direction: column;
      border-right: 10px solid #F8F8F8;
      padding-right: 10px;
      .list {
        margin-top: 10px;
        flex: 1;
        height: 0;
        display: flex;
        flex-direction: column;
        overflow: auto;
        &:empty::after {
          color: #999;
          text-align: center;
          content: '暂无数据';
        }
      }
      width: 200px;
    }
    .right {
      padding: 10px;
      box-sizing: border-box;
      flex: 1;
      display: flex;
      flex-direction: column;
    }
  }
  .tabs {
    display: inline-block;
    border: 1px solid #dedede;
    border-bottom: none;
    margin-top: 18px;
    span {
      transition: all .3s;
      color: #657180;
      font-size: 15px;
      display: inline-block;
      line-height: 35px;
      height: 35px;
      padding-left: 10px;
      padding-right: 10px;
      cursor: pointer;
      & + span {
        border-left: 1px solid #dedede;
      }
      &:hover, &.active {
        color: #657180;
      }
      &.active {
        background-color: #EEE;
      }
    }
  }
  .content-text {
    width: 100%;
    padding: 10px;
    display: block;
    border: 1px solid #dedede;
  }
  .worker-item {
    border: 1px solid #dedede;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
    line-height: 30px;
    margin: 0 10px;
    transition: all .3s;
    & + .worker-item {
      margin-top: 10px;
    }
    &:hover {
      color: #000;
    }
    &.active {
      background-color: #EEE;
      color: #000;
    }
  }
  .list-action {
    display: flex;
    justify-content: space-evenly;
    margin-top: 10px;
  }
  .batch-list {
    height: 0;
    flex: 1;
    overflow: auto;
  }
</style>

<template>
  <div class="evaluation-details-draw">
    <fm-title title-text="考核意见填写"></fm-title>
    <div style="display: flex;justify-content: space-between;align-items: center;padding-bottom: 10px;">
      <RadioGroup v-model="status.status" type="button">
        <Radio :label="1">待处理 - {{total.wait}}</Radio>
        <Radio :label="2">已处理 - {{total.done}}</Radio>
        <Radio :label="3">全部 - {{total.all}}</Radio>
      </RadioGroup>
      <div>
        <fm-btn v-if="tabs.length" @click="switchBatch" long>{{status.batch ? ('退出批量操作' + (chooseList.length > 0 ? ('（已选' + chooseList.length + '人）') : '')) : '批量操作'}}</fm-btn>
        <fm-btn @click="print" long>打印{{chooseList.length > 0 ? ('（' + chooseList.length + '份）') : ''}}</fm-btn>
      </div>
    </div>
    <div class="content" style="padding-bottom: 0;" v-loadingx="loading.load">
      <div class="left">
        <fm-input-new v-model="searchKey" placeholder="前置图标">
          <i class="fmico fmico-search" slot="prefix"></i>
        </fm-input-new>
        <div v-if="status.status !== 1" class="list scrollbar">
          <div :class="{
            active: (chooseData && chooseData.id === item.id) || chooseList.includes(item.id)
          }" @click="itemClick(item)" class="worker-item" v-for="item in workList" :key="item.id">{{item.workerName}}</div>
        </div>
        <div v-else class="list" style="overflow: hidden;">
          <div class="list" style="overflow: hidden;margin-top: 0;">
            <div>我的待处理（已提交）</div>
            <div class="list scrollbar">
              <div :class="{
                active: (chooseData && chooseData.id === item.id) || chooseList.includes(item.id)
              }" @click="itemClick(item)" class="worker-item" v-for="item in workList.filter(v => v.status === 'submit')" :key="item.id">{{item.workerName}}</div>
            </div>
          </div>
          <div class="list" style="overflow: hidden;">
            <div>我的待处理（未提交）</div>
            <div class="list scrollbar">
              <div :class="{
                active: (chooseData && chooseData.id === item.id) || chooseList.includes(item.id)
              }" @click="itemClick(item)" class="worker-item" v-for="item in workList.filter(v => v.status === 'draw')" :key="item.id">{{item.workerName}}</div>
            </div>
          </div>
        </div>
        <div class="list-action" v-if="status.batch">
          <fm-btn @click="checkedAll">全选</fm-btn>
          <fm-btn @click="checkedReverse">反选</fm-btn>
        </div>
      </div>
      <div class="right">
        <div v-if="status.batch" class="batch-list scrollbar">
          <div v-for="tab in tabs" :key="tab.key" v-loadingx="loading.batch[tab.key]">
            <div style="display: flex;justify-content: space-between; align-items: center;">
              <div class="tabs">
                <span>{{tab.label}}</span>
              </div>
              <div>
                <fm-btn @click="batchSubmit(tab.key, 'draw')">保存</fm-btn>
                <fm-btn @click="batchSubmit(tab.key, 'submit')">提交</fm-btn>
              </div>
            </div>
            <textarea rows="5" class="content-text" v-model="checkData[tab.key].content"></textarea>
          </div>
        </div>
        <DetailsContent @reload="reload" style="height: 0; flex: 1;" ref="content" v-else-if="chooseData" :data="chooseData" :type="type"/>
      </div>
    </div>
    <iframe style="display: none;" ref="iframe"></iframe>
  </div>
</template>

<script>
import { appraisalYearDetailsRequest, appraisalYearRequest } from '../../../api'
import DetailsContent from '../components/content.vue'
import { getDefaultCheckData, renderItem } from '../lib'

export default {
  components: { DetailsContent },
  props: {
    type: String
  },
  data () {
    return {
      status: {
        status: 1,
        batch: false
      },
      loading: {
        load: false,
        batch: {
          org: false,
          lead: false,
          other: false
        }
      },
      chooseList: [],
      data: [],
      chooseData: null,
      yearData: null,
      total: {
        done: 0,
        wait: 0,
        all: 0
      },
      checkData: {
        my: getDefaultCheckData(null, 'my'),
        org: getDefaultCheckData(null, 'org'),
        lead: getDefaultCheckData(null, 'lead'),
        other: getDefaultCheckData(null, 'other')
      },
      searchKey: null
    }
  },
  computed: {
    workList () {
      const status = this.status.status
      const searchKey = this.searchKey
      return this.data.filter(v => {
        return (status === 3 || v.isUpcoming === (status === 1 ? true : false)) && (!searchKey || String(v.workerName).indexOf(searchKey) > -1)
      })
    },
    tabs () {
      const funs = this.$authFunProxy
      const orgUserIds = !this.yearData ? [] : (this.yearData.orgUserIds || '').split(',').filter(v => v).map(v => Number(v))
      const leadUserIds = !this.yearData ? [] : (this.yearData.leadUserIds || '').split(',').filter(v => v).map(v => Number(v))
      const currentUserId = this.$store.getters.currentUserId
      return [
        { label: '考核单位审核意见', key: 'org', auth: orgUserIds.includes(currentUserId) },
        { label: '主管领导审核意见', key: 'lead', auth: leadUserIds.includes(currentUserId) },
        { label: '未确定等次或不参加考核情况说明', key: 'other', auth: orgUserIds.includes(currentUserId) }
      ].filter(v => {
        return funs['hrms.evaluation.index-' + v.key + 'check'] && v.auth !== false
      })
    }
  },
  methods: {
    print () {
      let datas = []
      if (this.chooseData) {
        datas = [this.chooseData]
      } else {
        datas = this.chooseList.map(id => {
          return this.data.find(v => v.id === id)
        })
      }

      if (!datas.length) {
        return this.$notice.warning('请选择要打印的数据')
      }

      let html = datas.map(item => renderItem(this.yearData.year, '太湖县妇幼保健院', item)).join('<div style="page-break-after: always;"></div>')
      // eslint-disable-next-line no-useless-escape
      this.$refs.iframe.srcdoc = '<!DOCTYPE html><head><link rel="stylesheet" href="/static/document/year-evaluation.css"></head><body>' + html + "</body><script>document.addEventListener('DOMContentLoaded', function () {setTimeout(function () {window.print();}, 0);});<\/script></html>"
    },
    resetCheckData () {
      this.tabs.forEach(v => {
        this.checkData[v.key] = getDefaultCheckData(null, v.key)
      })
    },
    checkedAll () {
      this.chooseList = this.data.map(v => v.id)
    },
    checkedReverse () {
      this.chooseList = this.data.filter(v => {
        return !this.chooseList.includes(v.id)
      }).map(v => v.id)
    },
    switchBatch () {
      this.status.batch = !this.status.batch
      this.chooseList = []
      if (this.status.batch) {
        this.chooseData = null
      } else {
        this.chooseData = this.data[0]
      }
    },
    itemClick (item) {
      if (this.status.batch) {
        this.chooseData = null
        if (this.chooseList.includes(item.id)) {
          const index = this.chooseList.findIndex(v => v === item.id)
          index > -1 && this.chooseList.splice(index, 1)
        } else {
          this.chooseList.push(item.id)
        }
      } else {
        this.chooseList = []
        this.chooseData = item
      }
    },
    async batchSubmit (type, status) {
      this.loading.batch[type] = true
      try {
        if (!this.chooseList.length) {
          throw new Error('请选择要操作的数据')
        }
        
        const checkDatas = this.chooseList.map(id => {
          return {
            content: this.checkData[type].content,
            type: type,
            status: status,
            appraisalYearDetailId: id
          }
        })

        await appraisalYearDetailsRequest.check(checkDatas)
        this.loadData()
        this.$notice.success('操作成功')
      } catch (error) {
        this.$notice.error(error.message)
      }
      this.loading.batch[type] = false
    },
    async loadData () {
      if (!this.$route.query.id) {
        return this.$router.replace({ name: 'hrms.evaluation.index' })
      }
      let param = {
        appraisalYearId: this.$route.query.id
      }

      this.loading.load = true
      const res = await appraisalYearDetailsRequest.get(param)

      if (res.length) {
        this.data = res
        if (this.chooseData) {
          this.chooseData = res.find(v => v.id === this.chooseData.id)
        } else {
          this.chooseData = res[0]
        }
        this.updateTotal()

        const yearRes = await appraisalYearRequest.get({ id: this.$route.query.id })
        this.yearData = yearRes.pop()
      } else {
        this.$notice.error('数据不存在')
        this.$router.back()
      }
      this.loading.load = false
    },
    updateTotal () {
      this.total.done = this.data.reduce((a, b) => {
        return a + (b.isUpcoming ? 0 : 1)
      }, 0)
      this.total.wait = this.data.reduce((a, b) => {
        return a + (b.isUpcoming ? 1 : 0)
      }, 0)
      this.total.all = this.data.length
    },
    async reload ({ id }) {
      const res = await appraisalYearDetailsRequest.get({ id })
      const index = this.data.findIndex(v => v.id === id)
      if (res.length && index > -1) {
        this.data.splice(index, 1, res[0])
        if (this.chooseData && this.chooseData.id === id) {
          this.chooseData = res[0]
        }
        this.updateTotal()
      }
    }
  },
  mounted () {
    if (this.$authFunProxy['hrms.evaluation.index-orgcheck'] || this.$authFunProxy['hrms.evaluation.index-leadcheck']) {
      this.loadData() 
    }
  }
}
</script>
